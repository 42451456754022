import { Dispatch, RefObject, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';

import { ToastMessageRef, ToastSeverity } from 'components/ToastMessage';
import { useGetRecipients } from 'modules/DistList/Services/DistListService';
import { replaceItemAt } from 'helpers/Utils/collections';

import { DL_MESSAGES_STATUS_LABEL, DLMessagesStatus } from '../../Models/Enums';

import CreateNewButton from './CreateNewButton';
import DroppedTooltip from './DroppedTooltip';
import EmptyMessage from './EmptyMessage';
import HeaderTemplate from './HeaderTemplate';
import ResendButton from './ResendButton';
import FooterTemplate from './FooterTemplate';

import type {
  DistributionListMessagesSearchResponse,
  DistributionListMessagesSearchResponseFlat,
  DistributionListResponse,
} from '../../Models/distribution-list-response';


interface MessageDesktopProps {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setSubjectSearch: Dispatch<SetStateAction<string | undefined>>;
  isLoading: boolean;
  isCreateDisabled: boolean;
  handleClose: () => void;
  toast: RefObject<ToastMessageRef>;
  setMessages: Dispatch<SetStateAction<DistributionListMessagesSearchResponse[] | undefined>>;
  setActiveDetailTab: Dispatch<SetStateAction<number>>;
  messages?: DistributionListMessagesSearchResponse[];
  activeDlId?: DistributionListResponse['id'];
}

const MessageDesktop = (props: MessageDesktopProps): JSX.Element => {
  const {
    messages,
    setMessages,
    searchValue,
    setSearchValue,
    setSubjectSearch,
    isLoading,
    isCreateDisabled,
    handleClose,
    toast,
    activeDlId,
    setActiveDetailTab,
  } = props;

  const [expandedRows, setExpandedRows] = useState<DistributionListMessagesSearchResponseFlat[]>([]);
  const [messagesUngrouped, setMessagesUngrouped] = useState<DistributionListMessagesSearchResponseFlat[] | undefined>(undefined);
  const [messageIdForRecipientsToLoad, setMessageIdForRecipientsToLoad] = useState<string | null>(null);
  const { emailIdParam } = useParams();

  const { trigger: getRecipients } = useGetRecipients();

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  // Convert [{id: 1, recipients: [{status: a}, {status: b}]}] => [{id: 1, status: a}, {id: 1, status: b}]
  const convertMessages = useCallback((data?: DistributionListMessagesSearchResponse[]): DistributionListMessagesSearchResponseFlat[] => {
    const elements: DistributionListMessagesSearchResponseFlat[] = [];

    return data?.reduce((acc, cur) => {
      if (cur.recipients?.length) {
        return [...acc, ...cur.recipients.map((rec, i) => ({ ...cur, ...rec, idKey: `${cur.id}_${i}` }))] as DistributionListMessagesSearchResponseFlat[];
      } else {
        return [...acc, { ...cur, idKey: `${cur.id}_0` }] as DistributionListMessagesSearchResponseFlat[];
      }
    }, elements) ?? [];
  }, []);

  const onRowToggle = async (e: { data: DistributionListMessagesSearchResponseFlat[]; }): Promise<void> => {
    setExpandedRows(e.data);

    const expandedRow = e.data.find(i => !expandedRows.includes(i));

    try {
      if (expandedRow) {
        setMessageIdForRecipientsToLoad(expandedRow.id);
        const res = await getRecipients({ id: expandedRow.id, distributionListId: expandedRow.distributionListId });

        setMessages(msgs => {
          if (msgs) {
            const index = msgs.findIndex(p => p.id === expandedRow.id);
            return replaceItemAt(msgs, res, index);
          }

          return msgs;
        });
      }
    } catch (e) {
      console.log('[DistList] Loading recipients error', e);
    }
  }

  useEffect(() => {
    if (messages !== undefined && !isLoading) {
      setMessagesUngrouped(convertMessages(messages));
      setMessageIdForRecipientsToLoad(null);
    }
  }, [convertMessages, isLoading, messages]);

  useEffect(() => {
    if (emailIdParam && !isLoading && messagesUngrouped !== undefined) {
      const foundMessage = messagesUngrouped?.find(c => c.id === emailIdParam);
      if (foundMessage) {
        setExpandedRows([foundMessage]);
      } else {
        toast.current?.replace({
          title: 'Wrong Subject ID',
          message: 'Provided Subject ID in link was not found',
          severity: ToastSeverity.WARN
        });
      }
    }
  }, [emailIdParam, messages, isLoading, toast, messagesUngrouped]);

  return (
    <>
      <div className="dl-messages-container grow-to-fill">
        <div className="dl-messages-search-container">
          <span className="p-input-icon-left grow-to-fill">
            <i
              className={clsx('pi', {
                'pi-spinner pi-spin': isLoading,
                'pi-search': !isLoading,
              })}
            />
            <InputText
              placeholder="Search by subject"
              value={searchValue}
              onChange={(e): void => setSearchValue(e.target.value)}
              onBlur={(e): void => setSubjectSearch(e.target.value)}
              onKeyUp={(e): boolean | void => e.key === 'Enter' && (e.target as HTMLInputElement).blur()}
              className="dl-search-input"
            />
          </span>
          <CreateNewButton activeDlId={activeDlId} isDisabled={isCreateDisabled}/>
        </div>
        <DataTable
          className={clsx('distlist__table--messages', {
            'grow-to-fill': messagesUngrouped && messagesUngrouped.length > 0
          })}
          dataKey="idKey"
          loading={isLoading}
          value={messagesUngrouped || []}
          scrollable
          emptyMessage=" "
          rowGroupMode="subheader"
          expandableRowGroups
          expandedRows={expandedRows}
          groupRowsBy="id"
          onRowToggle={onRowToggle}
          rowGroupFooterTemplate={(data) => <FooterTemplate data={data} messageIdForRecipientsToLoad={messageIdForRecipientsToLoad} />}
          rowGroupHeaderTemplate={HeaderTemplate}
        >
          <Column
            header="Recipient"
            field="emailAddress"
          />
          <Column
            header="Status"
            field="status"
            body={(data): JSX.Element => !data.recipients?.length ?
              <></> :
              [DLMessagesStatus.dropped].includes(data.status) ?
                <>{DL_MESSAGES_STATUS_LABEL[DLMessagesStatus.dropped]}<DroppedTooltip /></> :
                <>{DL_MESSAGES_STATUS_LABEL[data.status as DLMessagesStatus ?? DLMessagesStatus.null]}</>}
            className="column-status"
          />
          <Column
            header="Attachment downloaded"
            field="attachmentDownloaded"
            body={(data): JSX.Element => data.attachmentDownloaded ? <i className="pi pi-check"></i> : <></>}
          />
          <Column header="Action"
            className="column-action"
            body={(data): JSX.Element =>
              [undefined, DLMessagesStatus.null, DLMessagesStatus.processed, DLMessagesStatus.deferred].includes(data.status) ?
                <></> :
                <ResendButton data={data} messages={messages} setMessages={setMessages}/>
            }/>
        </DataTable>
        {messages?.length === 0 &&
          <EmptyMessage activeDlId={activeDlId} isCreateDisabled={isCreateDisabled} setActiveDetailTab={setActiveDetailTab} />}
      </div>
      <Button
        text
        icon="iconoir-xmark icon--tiny p-button-icon-only"
        className={clsx('close-button', {hidden: isMobile})}
        onClick={handleClose}
      />
    </>
  );
};

export default MessageDesktop;
